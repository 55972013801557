import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { TextField, Button, Grid, Divider, Stack, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DataGridPremium, GridRowEditStopReasons, GridRowModes } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';

/** Material UI Icons. */
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import { customized_style } from '../../../theme/Styles';
import { CustomExportToolbar } from '../../CustomExport';

export default function ChassisEntry(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from DataEntry.js.  */
    const { chassis, setChassis } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /**  Chassis Info Datagrid row. */
    const [chassisRow, setChassisRow] = useState([]);
    /** When double-click, the row will be activated on Datagrid. */
    const [rowModesModel, setRowModesModel] = useState({});
    /** State for chassis type info. */
    const [type, setType] = useState('');
    /** Define chassis type info.  */
    const chassisType = [
        { id: 0, type: 'Insight' },
        { id: 1, type: 'Partner' },
        {
            id: 2,
            type: 'Terminal',
        },
    ];

    /** Populate chassis info through API. */
    useEffect(() => {
        const new_row = chassis ? [...chassis] : [];
        setChassisRow(new_row);
    }, [chassis]);

    /** New division info is added on Datagrid using submit button. */
    const addNewChassis = (event) => {
        event.preventDefault(); // To prevent Datagrid re-rendering.
        const form_data = new FormData(event.target);
        const last_id = chassisRow.length === 0 ? 0 : chassisRow[chassisRow.length - 1].id;
        const new_data = {
            id: parseInt(last_id) + 1,
            name: form_data.get('chassis_name'),
            type: parseInt(form_data.get('type')),
            status: true,
        };
        const new_chassis = [...chassisRow, new_data];
        setChassisRow(new_chassis);
        apiRequest('dataentry', 'chassis', userId, new_data)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setChassis(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
    };

    /** Handler for Datagrid edit stop props. */
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };
    /** Datagrid Row edit handler for division Info. */
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    /** Datagrid Row save handler for division Info. */
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
        setSnackbarOpen(true);
    };

    /** rowModesModel state change to View mode. */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = chassisRow.find((row) => row.id === id);
        if (editedRow.isNew) {
            setChassisRow(chassisRow.filter((row) => row.id !== id));
        }
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setChassisRow(chassisRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        apiRequest('dataentry', 'chassis', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setChassis(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
        return updatedRow;
    };

    const selectTypeChange = (event) => {
        setType(event.target.value);
    };

    /** When double click, activate Row to change. */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /** Define division Datagrid Fields. */
    const chassis_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
        {
            field: 'name',
            headerName: 'Name',
            width: 200,
            editable: true,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 200,
            editable: true,
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            valueOptions: [...chassisType].map((item) => ({
                value: item.id,
                label: item.type,
            })),
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            editable: true,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 130,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: '#00647e',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            sx={{
                                color: '#00647e',
                            }}
                        />,
                    ];
                }
                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <Grid container>
            <Grid item xs={4}>
                <Box component="form" onSubmit={addNewChassis}>
                    <TextField
                        required
                        fullWidth
                        id="chassis_name"
                        label="Chassis Name"
                        name="chassis_name"
                        autoComplete="division_name"
                    />
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel id="type" required>
                            Type
                        </InputLabel>
                        <Select
                            labelId="type"
                            id="type"
                            label="Type"
                            name="type"
                            value={type}
                            onChange={selectTypeChange}
                            required
                        >
                            {chassisType.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack direction="row" justifyContent="center">
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<PersonAddAltIcon />}
                            sx={{ ...customized_style.itemButtons_add, width: '44%', mt: 3 }}
                        >
                            Add New Chassis
                        </Button>
                        <CustomSnackbar
                            snackbarOpen={snackbarOpen}
                            setSnackbarOpen={setSnackbarOpen}
                            snackMsg={snackMsg}
                            snackSeverity={snackSeverity}
                        />
                    </Stack>
                </Box>
            </Grid>
            <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />
            <Grid item xs={7.5} sx={{ height: '77vh' }}>
                <DataGridPremium
                    rows={chassisRow}
                    columns={chassis_col}
                    editMode="row"
                    unstable_cellSelection
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    disableRowSelectionOnClick
                    sx={customized_style.itemDatagrid}
                    slots={{ toolbar: CustomExportToolbar }}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    pagination={true}
                    pageSizeOptions={[25, 50, 100]}
                />
            </Grid>
        </Grid>
    );
}
