import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Paper, Typography, FormControl, Button, TextField } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MultiInputDateRangeField } from '@mui/x-date-pickers-pro/MultiInputDateRangeField';

/** HTTP Client Library. */
import axios from 'axios';

/** Authenticator Method */
import { useAuth0 } from '@auth0/auth0-react';

/** Internal Components. */
import { CustomSkeletonVer2 } from '../../CustomSkeleton';
import { customized_style } from '../../../theme/Styles';
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';
import { CustomExportToolbar } from '../../CustomExport';

export default function Logs(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /** Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** State to display loading component until API response is received. */
    const [loaded, setLoaded] = useState(false);
    /** User log datagrid row. */
    const [userLogsRow, setUserLogsRow] = useState([]);
    /** State for a selected date range. */
    const [selectedDate, setSelectedDate] = useState([]);
    /** State for a input value. */
    const [inputValue, setInputValue] = useState('');

    /** Date format for datagrid. */
    const datetime_format = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    /** Set document title. */
    useEffect(() => {
        document.title = 'Logs - Insight Logistics | Dispatch';
    }, []);

    /** Define user log Datagrid Fields. */
    const userLogsCol = [
        { field: 'id', headerName: 'ID', width: 30, align: 'center', headerAlign: 'center' },
        {
            field: 'component',
            headerName: 'Component',
            width: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'tableName',
            headerName: 'Table Name',
            width: 150,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'transaction',
            headerName: 'Transation',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'entry_value',
            headerName: 'Entry Value',
            width: 800,
            headerAlign: 'center',
            valueFormatter: (params) => (params.value ? params.value.replace(/[{}]/g, '') : ''),
        },
        {
            field: 'modified_by',
            headerName: 'Modified By',
            width: 130,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => {
                const value = params.value || '';
                return value.split('@')[0].toUpperCase();
            },
        },
        {
            field: 'modified_date',
            headerName: 'Modified Date',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', datetime_format) : null,
        },
    ];

    /** Async API call to obtain user log data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'log',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setUserLogsRow(resp.body.data);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    /** Handle to submit search conditions and receive new data for datagrid row.  */
    const handleSearch = () => {
        const searchValue = {
            date_from: selectedDate ? selectedDate[0] : null,
            date_to: selectedDate ? selectedDate[1] : null,
            search_input: inputValue,
        };
        /** POST request to DB */
        apiRequest('log', 'search', userId, searchValue)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setUserLogsRow(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Something went wrong. Please try again.');
            });
    };

    /** Handle to get a TextField value. */
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    /** Handle to reset all search conditions. */
    const handleReset = () => {
        setSelectedDate([]);
        setInputValue('');
    };

    return (
        <>
            <Paper sx={{ ...customized_style.itemPaper_main, p: 2 }}>
                {loaded ? (
                    <>
                        <Typography
                            sx={{ display: 'inline', fontSize: '0.9rem', mr: 0.5, fontWeight: 500, color: '#003a52' }}
                        >
                            Date Range :
                        </Typography>
                        <FormControl sx={{ ml: 1, width: '300px', alignItems: 'center' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MultiInputDateRangeField
                                    value={selectedDate}
                                    onChange={(newValue) => setSelectedDate(newValue)}
                                    slotProps={{
                                        textField: ({ position }) => ({
                                            label: position === 'start' ? 'Date From' : 'Date To',
                                            size: 'small',
                                            style: { fontSize: '0.9rem' },
                                            InputProps: {
                                                style: { fontSize: '0.9rem' },
                                            },
                                            InputLabelProps: {
                                                style: { fontSize: '0.9rem' },
                                            },
                                        }),
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': { p: '7px' },
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        <TextField
                            id="search_input"
                            size="small"
                            label="Search"
                            value={inputValue}
                            onChange={handleInputChange}
                            sx={{ width: '200px', ml: 2 }}
                            InputProps={{
                                sx: { height: 35, fontSize: '0.8rem' },
                            }}
                        />
                        <Button
                            onClick={handleSearch}
                            sx={{
                                bgcolor: '#00657e',
                                color: 'white',
                                borderRadius: 5,
                                ml: 2,
                                px: 2,
                                ':hover': {
                                    backgroundColor: '#003a52',
                                    color: 'white',
                                },
                            }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={handleReset}
                            sx={{
                                color: '#00657e',
                                borderRadius: 5,
                                border: '1px solid #00657e',
                                ml: 1,
                                px: 2,
                                ':hover': {
                                    backgroundColor: '#003a52',
                                    color: 'white',
                                },
                            }}
                        >
                            Clear
                        </Button>
                        <DataGridPremium
                            rows={userLogsRow ? userLogsRow : []}
                            columns={userLogsCol}
                            density="compact"
                            disableRowSelectionOnClick
                            slots={{ toolbar: CustomExportToolbar }}
                            sx={{ border: 'none', height: '83vh', fontSize: '0.8rem' }}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 25 } },
                            }}
                            pagination={true}
                            pageSizeOptions={[25, 50, 100]}
                            getRowHeight={() => 'auto'}
                        />
                    </>
                ) : (
                    <CustomSkeletonVer2 />
                )}
            </Paper>
            <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackMsg={snackMsg}
                snackSeverity={snackSeverity}
            />
        </>
    );
}
