import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';

/** Material UI Packages. */
import { Box, Button, Grid, Paper, CircularProgress } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Material UI Icons. */
import FindInPageIcon from '@mui/icons-material/FindInPage';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Liabrary. */
import axios from 'axios';

/** Internal Components. */
import HistoryTimeline from './HistoryTimeline';
import HistorySearch from './HistorySearch';
import HistorySubTable from './HistorySubTable';
import { CustomSkeletonVer2 } from '../../CustomSkeleton';
import { customized_style } from '../../../theme/Styles';
import { CustomExportToolbar } from '../../CustomExport';

export default function History(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /** Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    /** State to display loading component until API response is received. */
    const [loaded, setLoaded] = useState(false);
    const [subLoaded, setSubLoaded] = useState(true);

    /** Data for all history Datagird row. */
    const [allHistoryRow, setAllHistoryRow] = useState([]);
    const [driver, setDriver] = useState([]);
    const [timelineOpen, setTimelineOpen] = useState(false);

    /** Date format for datagrid. */
    const date_format = { year: '2-digit', month: '2-digit', day: '2-digit' };
    const datetime_format = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    /** Set document title. */
    useEffect(() => {
        document.title = 'History - Insight Logistics | Dispatch ';
    }, []);

    /** Handle to open Timeline dialog. */
    const handleClickOpenTimeline = () => {
        setTimelineOpen(true);
    };

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'history',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setAllHistoryRow(resp.body.history);
                setDriver(resp.body.driver);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    /** Define History Datagrid Fields. */
    const allHistoryCol = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center' },
        {
            field: 'dispatch_date',
            headerName: 'Date',
            type: 'date',
            width: 100,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'team',
            headerName: 'Team',
            width: 70,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'client',
            headerName: 'Client',
            width: 150,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'mbl',
            headerName: 'Booking# / MBL#',
            width: 170,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'cntr',
            headerName: 'CNTR#',
            width: 130,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 70,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'terminal',
            headerName: 'Terminal',
            width: 150,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'vessel',
            headerName: 'Vessel',
            width: 170,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'eta',
            headerName: 'ETA',
            type: 'date',
            width: 110,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'etb',
            headerName: 'ETB',
            type: 'date',
            width: 110,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'dem',
            headerName: 'DEM.LFD',
            type: 'date',
            width: 110,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'pdiem',
            headerName: 'P.DIEM.LFD',
            type: 'date',
            width: 110,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'appt',
            headerName: 'APPT',
            type: 'date',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', datetime_format) : null,
        },
        {
            field: 'empty_date',
            headerName: 'Empty Date',
            type: 'date',
            width: 110,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) =>
                params.value ? new Date(params.value).toLocaleDateString('en-US', date_format) : null,
        },
        {
            field: 'aim_chassis',
            headerName: 'AIM Chassis',
            width: 120,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'obl',
            headerName: 'OBL',
            width: 90,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'customs',
            headerName: 'Customs',
            width: 90,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'pierpass',
            headerName: 'PierPass',
            width: 90,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'remark',
            headerName: 'Remark',
            width: 150,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 90,
            type: 'boolean',
            align: 'center',
            headerAlign: 'center',
        },
    ];

    /** Get the content of the detail panel for a row in the DataGrid. */
    const getDetailPanelContent = useCallback(
        ({ row }) => {
            if (row.movement && row.movement.length > 0) {
                return <HistorySubTable row={row} />;
            }
            return null;
        },
        [allHistoryRow]
    );

    /** Returns 'auto' to allow the panel to adjust its height based on the content. */
    const getDetailPanelHeight = useCallback(() => 'auto', []);

    return (
        <>
            <Paper sx={{ ...customized_style.itemPaper_main, p: 2, pb: 0 }}>
                {loaded ? (
                    <>
                        <Grid container>
                            <Grid item sm={8} md={7.5}>
                                <HistorySearch
                                    userId={userId}
                                    setAllHistoryRow={setAllHistoryRow}
                                    setSubLoaded={setSubLoaded}
                                />
                            </Grid>
                            <Grid item sm={4} md={4.5}>
                                <Button sx={{ float: 'right' }} onClick={handleClickOpenTimeline}>
                                    <FindInPageIcon /> Timeline
                                </Button>
                            </Grid>
                        </Grid>
                        <Box>
                            {subLoaded ? (
                                <DataGridPremium
                                    rows={allHistoryRow}
                                    columns={allHistoryCol}
                                    editMode="row"
                                    density="compact"
                                    unstable_cellSelection
                                    disableRowSelectionOnClick
                                    slots={{
                                        toolbar: CustomExportToolbar,
                                    }}
                                    sx={{ mx: 1, border: 'none', height: '80vh', fontSize: '0.8rem' }}
                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}
                                    getDetailPanelHeight={getDetailPanelHeight}
                                    getDetailPanelContent={getDetailPanelContent}
                                />
                            ) : (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', height: '89vh' }}
                                >
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                    </>
                ) : (
                    <CustomSkeletonVer2 />
                )}
                <HistoryTimeline
                    userId={userId}
                    driver={driver}
                    timelineOpen={timelineOpen}
                    setTimelineOpen={setTimelineOpen}
                />
            </Paper>
        </>
    );
}
