import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Button } from '@mui/material';
import { DataGridPremium, GridRowEditStopReasons, GridRowModes, GridToolbarContainer } from '@mui/x-data-grid-premium';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { randomId } from '@mui/x-data-grid-generator';

/** Material UI Icons. */
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

/** Internal Components. */
import CustomSnackbar from '../../CustomSnackbar';
import useApiRequest from '../../useApiRequest';

import dayjs from 'dayjs';

/** Handler to add a new row. */
function EditToolbar(props) {
    const { setAnnounceRow, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setAnnounceRow((oldRows) => [
            ...oldRows,
            { id, create_date: '', memo: '', status: '', created_by: '', isNew: true },
        ]);
        // Update 'rowModesModel' state to set the editing mode for the new row
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'memo' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                Add Memo
            </Button>
        </GridToolbarContainer>
    );
}

export default function DashboardAnnouncement(props) {
    /** Get props from Dashboard.js.  */
    const {
        memoData,
        snackbarOpen,
        setSnackbarOpen,
        snackMsg,
        setSnackMsg,
        snackSeverity,
        setSnackSeverity,
        setMemoData,
        userId,
    } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** When double-click, the row will be activated on Datagrid. */
    const [rowModesModel, setRowModesModel] = useState({});
    const [announceRow, setAnnounceRow] = useState([]);

    /** Status Option for annoucement datagrid */
    const STATUS_OPTIONS = ['Important', 'Reminder', 'Completed', 'General', 'Announcements'];

    /** Automatically soring by perdiem date. */
    const [sortModel, setSortModel] = useState([{ field: 'create_date', sort: 'asc' }]);

    /** Receive original data through API. */
    useEffect(() => {
        setAnnounceRow(memoData);
    }, [memoData]);

    /** Datagrid Row delete handler for memo. */
    const handleDeleteClick = (id) => () => {
        if (window.confirm('Are you sure you want to delete the entry?')) {
            const deleted_obj = announceRow.find((row) => row.id === id).id;
            /** POST request to DB */
            apiRequest('dashboard', 'memo-delete', userId, [deleted_obj])
                .then((response) => {
                    if (response.statusCode === 200) {
                        setSnackbarOpen(true);
                        setSnackSeverity('success');
                        setSnackMsg(response.body.message);
                    } else {
                        setSnackbarOpen(true);
                        setSnackSeverity('error');
                        setSnackMsg(response.body.message);
                    }
                })
                .catch((error) => {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg('Something went wrong. Please try again.');
                });
            setAnnounceRow(announceRow.filter((row) => row.id !== id));
        }
    };

    /** Handler for Datagrid edit stop props. */
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    /** Datagrid Row edit handler for announce memo. */
    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    /** Datagrid Row save handler for announce memo. */
    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    /** rowModesModel state change to View mode. */
    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
        const editedRow = announceRow.find((row) => row.id === id);
        if (editedRow.isNew) {
            setAnnounceRow(announceRow.filter((row) => row.id !== id));
        }
    };

    /** Handler to update row with new values for changed parts. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setAnnounceRow(announceRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        /** POST request to DB */
        apiRequest('dashboard', 'memo', userId, updatedRow)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                    setMemoData(response.body.data);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg('Oops, something went wrong :(');
            });
        return updatedRow;
    };

    /** When double click, activate Row to change. */
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /** Define Announce board Datagrid Fields. */
    const announce_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center', hide: true },
        {
            field: 'create_date',
            headerName: 'Date',
            width: 95,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => (params.value ? dayjs(params.value).format('MM/DD/YY') : null),
        },
        { field: 'memo', headerName: 'Memo', width: 300, align: 'center', headerAlign: 'center', editable: true },
        {
            field: 'status',
            headerName: 'Status',
            type: 'singleSelect',
            width: 130,
            editable: true,
            valueOptions: STATUS_OPTIONS,
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'created_by',
            headerName: 'Create By',
            width: 80,
            align: 'center',
            headerAlign: 'center',
            valueFormatter: (params) => {
                const value = params.value || '';
                return value.split('@')[0].toUpperCase();
            },
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 95,
            cellClassName: 'actions',
            align: 'center',
            headerAlign: 'center',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label="Save"
                            sx={{
                                color: '#00647e',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            sx={{
                                color: '#00647e',
                            }}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="primary.main"
                    />,
                ];
            },
        },
    ];
    return (
        <Box
            sx={{
                height: '69.5vh',
                '& .super-app-theme--header': {
                    color: '#ff9f1c',
                },
            }}
        >
            <DataGridPremium
                rows={announceRow}
                columns={announce_col}
                editMode="row"
                rowHeight={38}
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                sortModel={sortModel}
                disableRowSelectionOnClick
                hideFooter
                slots={{ toolbar: EditToolbar }}
                slotProps={{
                    toolbar: { setAnnounceRow, setRowModesModel, showQuickFilter: true },
                }}
                sx={{ width: '100%', border: 'none', boxShadow: 'none' }}
                getRowHeight={() => 'auto'}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
            />
            <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackMsg={snackMsg}
                snackSeverity={snackSeverity}
            />
        </Box>
    );
}
