import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';

/** Material UI Packages. */
import { Button, Stack, Chip, Paper, Box, Typography, CircularProgress, TextField } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

/** Material UI Icons. */
import FaceIcon from '@mui/icons-material/Face';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

/** Internal Components. */
import useApiRequest from '../../useApiRequest';
import RateDriverList from './RateDriverList';
import CustomSnackbar from '../../CustomSnackbar';
import { customized_style } from '../../../theme/Styles';
import { CustomExportToolbar2 } from '../../CustomExport';

import dayjs from 'dayjs';
import _ from 'lodash';
import { fontSize } from '@mui/system';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default function RateContent(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get Dialog props from Rate.js */
    const {
        driver,
        tabContent,
        setTabContent,
        tabValue,
        item,
        allDriverList,
        saveList,
        setSaveList,
        left,
        setLeft,
        right,
        setRight,
        addCharge,
        city,
        saveBtnDisable,
        setSaveBtnDisable,
        rowUpdates,
        idList,
    } = props;

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Driver assignment dialog state. */
    const [rateDriverListDialogOpen, setRateDriverListDialogOpen] = useState(false);

    /** Column Definition for DataGrid on Rates, depending on the activated city list. */
    const [rateColDef, setRateColDef] = useState([]);
    /** Column definition for additional charges, dynamically populated with the activated values. */
    const [additionalChargeRate_col, setAdditionalChargeRate_col] = useState([]);

    /** API Context */
    const apiRequest = useApiRequest();

    /** Manage CircularProgress component when click saveBtn. */
    const [loading, setLoading] = useState(false);

    const [rowSelection, setRowSelection] = useState(false);
    const [copiedData, setCopiedData] = useState('');
    /** Rate apply date state for Datepicker. */
    const [rateApplyDate, setRateApplyDate] = useState(null);
    const [datePickerError, setDatePickerError] = useState(null);
    /** Check whether drivers have been assigned or no. */
    const [checkDriver, setCheckDriver] = useState(false);
    const [checkDate, setCheckDate] = useState(false);
    /** Check invalid apply date or no. */
    const [errorState, setErrorState] = useState(false);

    /** Hook to confirm if the selected date is a valid value. */
    useEffect(() => {
        const today_date = dayjs().format('MM/DD/YY');
        const selected_date = rateApplyDate?.format('MM/DD/YY');
        const new_date_applied = [...tabContent].map((item, idx) => {
            if (rateApplyDate && idx === tabValue) {
                item.date_applied = rateApplyDate;
            }
            return item;
        });
        // When change rateApplyDate, save btn will be activated.
        {
            rateApplyDate != null ? setSaveBtnDisable(false) : <></>;
        }
        // Check conditions to determine the error state and update setErrorState accordingly.
        setErrorState(today_date <= selected_date || rateApplyDate == null ? false : true);
        setTabContent(new_date_applied);
        // setSaveBtnDisable(false);
    }, [rateApplyDate]);

    /** Driver assignment dialog open handler. */
    const handleDriverListDialogOpen = () => {
        setLeft(left.sort());
        setRateDriverListDialogOpen(true);
    };

    /** Dynamically generate an error message based on the 'datePickerError'. */
    const errorMessage = useMemo(() => {
        switch (datePickerError) {
            case 'disablePast':
            case 'minDate': {
                return 'Please select a future date';
            }
            case 'invalidDate': {
                return 'Your date is not valid';
            }
            default: {
                return '';
            }
        }
    }, [datePickerError]);

    /** Define city rate Datagrid Fields. */
    useEffect(() => {
        const new_cityRateColDef = [...city].map((item) => {
            return {
                field: item.name,
                headerName: item.name,
                width: 100,
                editable: true,
                align: 'center',
                headerAlign: 'center',
                type: 'number',
                sortable: false,
                valueGetter: (params) => (params.value === 0 || params.value < 0 ? '' : params.value),
            };
        });
        const new_rateColDef = [
            {
                field: 'id',
                headerName: 'ID',
                width: 30,
                align: 'center',
                headerAlign: 'center',
                hide: true,
                sortable: false,
            },
            {
                field: 'movement',
                headerName: 'MOVEMENT',
                width: 250,
                align: 'center',
                headerAlign: 'center',
                sortable: false,
            },
            ...new_cityRateColDef,
        ];
        setRateColDef(new_rateColDef);
    }, [city]);

    /** Define additional charge rate Datagrid Fields. */
    useEffect(() => {
        const new_chargeColDef = [...addCharge].map((item) => {
            return {
                field: item.name,
                headerName: item.name,
                flex: 1,
                editable: true,
                align: 'center',
                headerAlign: 'center',
                type: 'number',
                sortable: false,
                valueGetter: (params) => (params.value === 0 || params.value < 0 ? '' : params.value),
            };
        });
        const new_rateColDef = [
            { field: 'id', headerName: 'ID', width: 30, align: 'center', headerAlign: 'center' },
            ...new_chargeColDef,
        ];
        setAdditionalChargeRate_col(new_rateColDef);
    }, [addCharge]);

    useEffect(() => {
        if (tabContent.length > saveList.length) {
            setCheckDriver(false);
        } else {
            [...saveList].map((item) => {
                item.name.length >= 1 ? setCheckDriver(true) : setCheckDriver(false);
            });
        }
        tabContent.map((item) => {
            if (item.date_applied) {
                setCheckDate(true);
            } else setCheckDate(false);
        });
    }, [tabContent, saveList]);

    /** Handler to save the updated rate sheet & open Snackbar. */
    const handleSaveBtn = () => {
        if (checkDriver == true && checkDate == true) {
            if (!rowUpdates.current) {
                const thisTab = [...tabContent][tabValue].content;
                rowUpdates.current = { [tabValue]: [...thisTab] };
            }
            setLoading(true);
            const edit_obj = Object.keys(rowUpdates.current)
                .map((str) => {
                    return [...tabContent].map((item, idx) => (idx === parseInt(str) ? item.id : null));
                })
                .flat()
                .filter((val) => val !== null);

            /** Transform tabContent object into array of object, as originally received via API */
            const api_tabContent = _.cloneDeep(tabContent)
                .map((item) => {
                    if (edit_obj.includes(item.id)) {
                        /** Transform each content and add content respectively. */
                        const new_content = item.content
                            .map((val) => {
                                const description_id = val.description_id; /** Obtain description id */
                                const excluded_cols = [
                                    'id',
                                    'city_name',
                                    'movement',
                                    'description_id',
                                    'isNew',
                                ]; /** Exclude irrelevant columns */
                                const obj = Object.entries({ ...val }).map((name) => {
                                    /** Iterate through values that do not involve excluded column names */
                                    if (!excluded_cols.includes(name[0])) {
                                        /** Get the city id */
                                        const city_id = [...city][[...city].findIndex((loc) => loc.name === name[0])]
                                            ?.id;
                                        return { description_id: description_id, city_id: city_id, rate: name[1] };
                                    }
                                });
                                return obj;
                            })
                            .flat()
                            .filter((val) => val !== undefined); /** Flatten the array of objects */
                        const new_addContent = item.addContent
                            .map((val) => {
                                const excluded_cols = ['id', 'profile_id', 'rate', 'additional_charge_name'];
                                const obj = Object.entries({ ...val }).map((name) => {
                                    if (!excluded_cols.includes(name[0])) {
                                        const charge_id = [...addCharge][
                                            [...addCharge].findIndex((chg) => chg.name === name[0])
                                        ]?.id;
                                        return { addCharge_id: charge_id, rate: name[1] };
                                    }
                                });
                                return obj;
                            })
                            .flat()
                            .filter((val) => val !== undefined)
                            .filter((val) => val.addCharge_id !== undefined);
                        const new_driverList = [...saveList]
                            .map((obj) => {
                                if (obj.id === item.id) {
                                    const applied_driver = obj.name
                                        .map((item) => {
                                            const driver_id = [...driver][
                                                [...driver].findIndex(
                                                    (val) => `${val.first_name} ${val.last_name}` === item
                                                )
                                            ]?.id;
                                            return { id: driver_id };
                                        })
                                        .flat();
                                    return applied_driver;
                                }
                            })
                            .flat()
                            .filter((val) => val !== undefined);
                        item.content = new_content;
                        item.addContent = new_addContent;
                        item.applied_from = item.date_applied;
                        item.driver_list = new_driverList;
                        return item;
                    }
                })
                .filter((item) => item !== undefined);
            apiRequest('rate', 'rate', userId, api_tabContent)
                .then((response) => {
                    setLoading(false);
                    if (response.statusCode === 200) {
                        if (!loading) {
                            setSnackbarOpen(true);
                            setSnackSeverity('success');
                            setSnackMsg(response.body.message);
                        }
                    } else {
                        if (!loading) {
                            setSnackbarOpen(true);
                            setSnackSeverity('error');
                            setSnackMsg(response.body.message);
                        }
                    }
                })
                .catch((error) => {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(error.response.message);
                });
            setSaveBtnDisable(true);
            setCheckDriver(false);
        } else {
            if (checkDate == false && checkDriver == true) window.alert('Please select the date.');
            else if (checkDate == true && checkDriver == false) window.alert('Please assign at least one driver.');
            else window.alert('Please assign drivers & date.');
        }
    };

    /** Delete tabpanel handler on Tab. */
    const deleteTab = () => {
        if (window.confirm('Are you sure you want to delete this sheet?')) {
            if (tabContent.length < 2) {
                return window.alert('You must have at least 1 rate sheet!');
            }
            const currentTabId = tabValue; //current tab index.
            const deleted_saveList = [...saveList] //Before delete tab, save driver list to left transfer list.
                .filter((item) => item.id === tabContent[currentTabId].id)
                .map((item) => item.name)
                .flat();
            setLeft((prev) => [...prev, ...deleted_saveList]);
            const updatedSaveList = [...saveList].filter((item) => item.id !== tabContent[currentTabId].id); //Delete assigned driver object from saveList.
            setSaveList(updatedSaveList);
            const deactivated = [tabContent[currentTabId]?.id];

            apiRequest('rate', 'deactivate', userId, deactivated)
                .then((response) => {
                    if (response.statusCode === 200) {
                        setSnackbarOpen(true);
                        setSnackSeverity('success');
                        setSnackMsg(response.body.message);
                    } else {
                        setSnackbarOpen(true);
                        setSnackSeverity('error');
                        setSnackMsg(response.body.message);
                    }
                })
                .catch((error) => {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(error.response.message);
                });
            rowUpdates.current = _.omit(rowUpdates.current, currentTabId); // Remove deleted tab content out of reference.
            const new_tabContent = [...tabContent].filter((item, idx) => idx !== currentTabId); // Filter out deleted tab content out of tab content.
            setTabContent((prev) => [...new_tabContent]);
        }
    };

    /** Handler to update city rate row with new values for changed parts. */
    const processRateUpdate = async (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        if (!rowUpdates.current) {
            rowUpdates.current = { [tabValue]: [newRow] };
        } else {
            if (rowUpdates.current[tabValue]) {
                const unsorted_update = [...rowUpdates.current[tabValue], newRow];
                const sorted_update = [...unsorted_update].sort((a, b) => b.description_id - a.description_id);
                const new_update = [...idList]
                    .map((id, idx) => {
                        const item = [...sorted_update].findLast((val) => val.description_id === id);
                        return item;
                    })
                    .filter((item) => item !== undefined);
                rowUpdates.current = { ...rowUpdates.current, [tabValue]: new_update };
            } else {
                rowUpdates.current = { ...rowUpdates.current, [tabValue]: [newRow] };
            }
        }
        setTabContent(
            [...tabContent].map((item, idx) =>
                idx === tabValue
                    ? {
                          ...item,
                          content: item.content.map(
                              (row) => [...rowUpdates.current[tabValue]].find((val) => val.id === row.id) || row
                          ),
                      }
                    : item
            )
        );
        setSaveBtnDisable(false);
        return updatedRow;
    };

    /* Handler to update additional charge row with new values for changed parts. */
    const processAddChargeUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setTabContent(
            tabContent
                .map((item, idx) =>
                    idx === tabValue
                        ? {
                              ...item,
                              addContent: item.addContent.map((row) => (row.id === newRow.id ? { ...newRow } : row)),
                          }
                        : item
                )
                .flat()
        );
        setSaveBtnDisable(false);
        return updatedRow;
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, overflow: 'auto' }}>
            <Box>
                <Box sx={{ display: 'block', float: 'left' }}>
                    <Button onClick={handleDriverListDialogOpen} sx={customized_style.itemButtonRound}>
                        <PersonAddAltIcon /> DRIVERS
                    </Button>
                    {saveList
                        .filter((item) => item.id === tabContent[tabValue].id)
                        .map((item) =>
                            item.name.map((obj, idx) => (
                                <Chip
                                    key={idx}
                                    icon={<FaceIcon />}
                                    label={obj}
                                    variant="outlined"
                                    sx={{ mr: 1, mb: 1 }}
                                />
                            ))
                        )}
                </Box>
                <Box sx={{ display: 'block', float: 'right' }}>
                    <Button variant="outlined" onClick={deleteTab} sx={{ mr: 2 }}>
                        DELETE
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ ...customized_style.itemButtons_save_opposite, mb: 0 }}
                        onClick={handleSaveBtn}
                        disabled={saveBtnDisable}
                    >
                        All SAVE
                    </Button>
                    {loading && (
                        <CircularProgress
                            size={50}
                            sx={{
                                color: 'primary.dark',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Box>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '100%' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        sx={{ mt: 2, width: '15%' }}
                        label="Rate Apply From"
                        disablePast
                        onError={(newError) => setDatePickerError(newError)}
                        slotProps={{
                            textField: {
                                size: 'small',
                                helperText: errorState == false ? null : errorMessage,
                                error: errorState,
                            },
                        }}
                        onChange={setRateApplyDate}
                    />
                </LocalizationProvider>
                <TextField
                    id="standard-read-only-input"
                    label="Current applied date"
                    defaultValue={
                        item.date_applied == undefined ? 'N/A' : dayjs.utc(item.date_applied).format('MM/DD/YY')
                    }
                    InputProps={{
                        readOnly: true,
                    }}
                    variant="standard"
                    sx={{ ml: 3, mt: 0.8 }}
                />
                <Typography sx={{ fontSize: '12px' }}>
                    ** Please note that all movements will be calculated with the following rate from the designated
                    date.
                </Typography>
            </Box>
            <DataGridPremium
                rows={item.content}
                columns={rateColDef}
                disableRowSelectionOnClick
                slots={{ toolbar: CustomExportToolbar2 }}
                density="compact"
                rowSelection={rowSelection}
                processRowUpdate={processRateUpdate}
                unstable_cellSelection
                disableRowGrouping
                hideFooter
                sx={{
                    ...customized_style.itemDatagrid,
                    '& .MuiDataGrid-cell': {
                        border: '1px solid #e9ecef',
                    },
                }}
                onClipboardCopy={(copiedString) => setCopiedData(copiedString)}
                experimentalFeatures={{ clipboardPaste: true }}
                unstable_ignoreValueFormatterDuringExport
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                    pinnedColumns: { left: ['movement'] },
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'asc' }],
                    },
                }}
            />
            <DataGridPremium
                rows={item.addContent}
                columns={additionalChargeRate_col}
                processRowUpdate={processAddChargeUpdate}
                disableRowSelectionOnClick
                disableRowGrouping
                // slots={{ toolbar: CustomExportToolbar2 }}
                onClipboardCopy={(copiedString) => setCopiedData(copiedString)}
                hideFooter
                unstable_cellSelection
                rowHeight={38}
                sx={{
                    ...customized_style.itemDatagrid,
                    '& .MuiDataGrid-cell': {
                        border: '1px solid #e9ecef',
                    },
                }}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            'Bobtail Start - 1st Yard': false,
                            'Bobtail Start - 2nd Yard': false,
                            Overweight: false,
                            Others: false,
                        },
                    },
                }}
            />
            <Stack direction="row" justifyContent="right">
                <CustomSnackbar
                    snackbarOpen={snackbarOpen}
                    setSnackbarOpen={setSnackbarOpen}
                    snackMsg={snackMsg}
                    snackSeverity={snackSeverity}
                />
            </Stack>
            <RateDriverList
                rateDriverListDialogOpen={rateDriverListDialogOpen}
                setRateDriverListDialogOpen={setRateDriverListDialogOpen}
                tabContent={tabContent}
                tabValue={tabValue}
                saveList={saveList}
                setSaveList={setSaveList}
                left={left}
                setLeft={setLeft}
                right={right}
                setRight={setRight}
                setSaveBtnDisable={setSaveBtnDisable}
                allDriverList={allDriverList}
                driver={driver}
            />
        </Box>
    );
}
