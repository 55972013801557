import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { MenuItem, Select } from '@mui/material';
import { DataGridPremium } from '@mui/x-data-grid-premium';

/** Internal Components. */
import { customized_style } from '../../../../theme/Styles';
import {
    CustomEditComponent,
    CustomStatusEditComponent,
    CustomSelectComponent,
    CustomDateTime,
} from './CustomEditCell';

import dayjs from 'dayjs';

export default function NewMoveTable(props) {
    /** Get props from Schedule.js */
    const { dispatch, assigned, locationOptions, chassis } = props;
    /** Get props from NewContainer.js.  */
    const { tempMoveRow, setTempMoveRow } = props;
    /** Get props from NewMoveDialog.js.  */
    const { setSaveBtnDisable } = props;

    /** Automatically soring by move_date. */
    const [sortModel, setSortModel] = useState([{ field: 'move_date', sort: 'asc' }]);

    /** Date format for datagrid. */
    const datetime_format = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };

    /** Handler to update a row with new values when a cell is edited. */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow };
        setTempMoveRow(tempMoveRow.map((row) => (row.id === newRow.id ? updatedRow : row)));
        setSaveBtnDisable(false);
        return updatedRow;
    };

    /** Update assigned row having Status: 'Canceled. */
    useEffect(() => {
        assigned.current = [...tempMoveRow].filter((row) => row.movementstatus.includes('Canceled'));
    }, [tempMoveRow]);

    /** Effect to change the isNew status to false for all rows once they are saved. */
    useEffect(() => {
        setTempMoveRow(tempMoveRow.map((item) => ({ ...item, isNew: false })));
    }, [dispatch]);

    /** Handler for changing the value in a select cell. */
    const handleSelectChange = (params) => (event) => {
        params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value });
    };

    /** Define NewMovement Datagrid Fields. */
    const move_col = [
        { field: 'id', headerName: 'ID', width: 50, align: 'center', headerAlign: 'center', hide: true },
        {
            field: 'move_date',
            headerName: 'Move Date',
            type: 'dateTime',
            width: 150,
            editable: true,
            align: 'center',
            headerAlign: 'center',
            // valueFormatter: (params) =>
            //     params.value ? new Date(params.value).toLocaleDateString('en-US', datetime_format) : null,
            // renderEditCell: (params) => <CustomEditComponent {...params} />,
            renderEditCell: (params) => <CustomDateTime {...params} />,
            valueFormatter: (params) =>
                params.value
                    ? `${new Date(params.value).toLocaleDateString('en-US', {
                          month: '2-digit',
                          day: '2-digit',
                          year: '2-digit',
                      })}, ${new Date(params.value).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                      })}`
                    : null,
        },
        {
            field: 'pickup',
            headerName: 'Pick Up',
            width: 180,
            editable: true,
            type: 'singleSelect',
            valueOptions: locationOptions.map((item) => ({ value: item.id, label: item.name })),
            align: 'center',
            headerAlign: 'center',
            renderEditCell: (params) => (
                <CustomSelectComponent
                    value={params.value}
                    options={locationOptions.map((item) => ({ value: item.id, label: item.name }))}
                    onChange={handleSelectChange(params)}
                />
            ),
        },
        {
            field: 'delivery_to',
            headerName: 'Delivery',
            width: 180,
            editable: true,
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            valueOptions: locationOptions.map((item) => ({ value: item.id, label: item.name })),
            valueFormatter: (params) => {
                const option = locationOptions[locationOptions.findIndex((item) => item.id === params.value)]?.name;
                return option;
            },
            renderEditCell: (params) => (
                <CustomSelectComponent
                    value={params.value}
                    options={locationOptions.map((item) => ({ value: item.id, label: item.name }))}
                    onChange={handleSelectChange(params)}
                />
            ),
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 80,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Load', 'Empty'],
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'method',
            headerName: 'Method',
            width: 80,
            editable: true,
            type: 'singleSelect',
            valueOptions: ['Live', 'Drop'],
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'chassis_id',
            headerName: 'Chassis',
            width: 110,
            editable: true,
            type: 'singleSelect',
            valueOptions: chassis.map((item) => ({ value: item.id, label: item.name })),
            valueFormatter: (params) => chassis.find((item) => item.id === params.value)?.name || '',
            align: 'center',
            headerAlign: 'center',
            renderEditCell: (params) => (
                <Select
                    value={params.value}
                    onChange={(event) =>
                        params.api.setEditCellValue({ id: params.id, field: params.field, value: event.target.value })
                    }
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 250,
                            },
                        },
                    }}
                    sx={{ fontSize: '12px', width: '100%' }}
                >
                    <MenuItem value="">N/A</MenuItem>
                    {chassis.map((option) => (
                        <MenuItem key={option.id} value={option.id} sx={{ fontSize: '12px' }}>
                            {option.name}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            field: 'movementstatus',
            headerName: 'Status',
            width: 120,
            editable: true,
            type: 'singleSelect',
            align: 'center',
            headerAlign: 'center',
            renderEditCell: (params) => <CustomStatusEditComponent {...params} />,
        },
    ];

    return (
        <DataGridPremium
            rows={tempMoveRow}
            columns={move_col}
            editMode="row"
            density="compact"
            processRowUpdate={processRowUpdate}
            sx={{
                ...customized_style.itemDatagrid,
                fontSize: '12px',
                p: 0,
                '& .MuiSelect-select': {
                    fontSize: '12px',
                },
                '& .MuiSelect-outlined': {
                    p: 1,
                },
                '& .MuiButtonBase-root': {
                    fontSize: '12px',
                },
            }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            //Temporary comment out
            // isCellEditable={(params) => {
            //     const localMoveDate = new Date(params.row.move_date).toLocaleDateString();
            //     const moveDateCondition = dayjs(localMoveDate) >= dayjs().subtract(4, 'day');
            //     const paymentStatusCondition = params.row.payment_status == false;
            //     return moveDateCondition && paymentStatusCondition;
            // }}
            initialState={{
                columns: {
                    columnVisibilityModel: {
                        id: false,
                    },
                },
            }}
        />
    );
}
