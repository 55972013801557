import * as React from 'react';
import { useState, useEffect, useCallback, useRef } from 'react';

/** Material UI Packages. */
import { Button, Stack, Typography, Box, IconButton } from '@mui/material';

/** Material UI Icons. */
import SaveIcon from '@mui/icons-material/Save';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

/** Drag n Drop Library. */
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

/** Internal Components. */
import TimelineCalendar from './TimelineCalendar';
import ChassisStatus from './ChassisStatus';
import MemoFab from './MemoFab';
import ScheduleFab from './ScheduleFab';
import { fieldStyle, customized_style } from '../../../../theme/Styles';
import CustomSnackbar from '../../../CustomSnackbar';
import useApiRequest from '../../../useApiRequest';
import { onDragEnd } from './TimelineDnd';
import load from '../../../../../statics/icons/load.png';
import empty from '../../../../../statics/icons/empty.png';

import _ from 'lodash';
import dayjs from 'dayjs';

export default function Timeline(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Get props from Schedule.js.  */
    const {
        driver,
        dispatch,
        setDispatch,
        locationOptions,
        selectedDate,
        setSelectedDate,
        setSelectedId,
        setSelectedCntr,
        setTempMoveRow,
        setNewMovementDialogOpen,
        chassis,
        chassisLocation,
        setChassisLocation,
    } = props;

    /** Custom API Request function */
    const apiRequest = useApiRequest();

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Detect changes in the window size and maintain that size as a state in the component. */
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    /** State for object containing all the data related to D&D.*/
    const [taskStatusCol, setTaskStatusCol] = useState({});
    /** Check a state for save button activation. */
    const [saveBtnDisable, setSaveBtnDisable] = useState(true);

    /** Date format for Timeline carding. */
    const time_format = { hour: '2-digit', minute: '2-digit', hour12: false };

    /** Callback function to handle window resize events. */
    const handleWindowResize = useCallback((event) => {
        // Update the window size when a resize event occurs.
        const newWindow = {
            width: window.innerWidth,
            height: window.innerHeight,
        };
        setWindowSize(newWindow);
    }, []);

    /** Effect to add and remove window resize event listener. */
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [handleWindowResize]);

    /**
     * Update taskStatusCol whenever you drag and drop movement cards in the timeline.
     */
    useEffect(() => {
        const movements = _.cloneDeep(dispatch)
            .map((item) => item.movement)
            .flat();
        // Filter movements based on selectedDate and exclude Status:'Canceled'.
        const filtered_movements = movements.filter((item) => {
            const local_move_date = new Date(item.move_date).toLocaleDateString();
            return (
                dayjs(local_move_date).format('YYYY-MM-DD') === selectedDate.format('YYYY-MM-DD') &&
                !item.movementstatus.includes('Canceled')
            );
        });
        // Filter movements into unallocated and allocated based on driver_id.
        const filtered_movements_unallocated = [...filtered_movements]
            .filter((item) => item.driver_id === null)
            .sort((a, b) => dayjs(a.move_date) - dayjs(b.move_date));
        filtered_movements_unallocated.map((item, idx) => {
            item.order_by = idx;
            return item;
        });
        const filtered_movements_allocated = [...filtered_movements].filter((item) => item.driver_id !== null);
        // Create a new taskStatusCol structure.
        const new_taskStatusCol = {
            movement: {
                name:
                    selectedDate.format('YYYY.MM.DD') === dayjs(new Date()).format('YYYY.MM.DD')
                        ? "TODAY'S MOVEMENT"
                        : selectedDate.format('YYYY.MM.DD'),
                driver_id: null,
                items: [...filtered_movements_unallocated],
            },
        };
        // Populate taskStatusCol with data for each driver.
        const field_data = [...driver].map((item, idx) => {
            const driverKey = `driver${idx}`;
            return (new_taskStatusCol[driverKey] = {
                name: `${item.last_name} ${item.first_name}`,
                driver_id: item.id,
                items: [...filtered_movements_allocated]
                    .filter((el) => el.driver_id === item.id)
                    .sort((a, b) => a.order_by - b.order_by),
            });
        });
        // Update the taskStatusCol state.
        setTaskStatusCol({
            ...new_taskStatusCol,
        });
    }, [dispatch, selectedDate, driver]);

    /** Handler to save final data using apiRequest. */
    const handleSaveBtn = () => {
        // Temporary commentout
        // const today = new Date();
        // const available_date = new Date();
        // available_date.setDate(today.getDate() - 3);
        // if (selectedDate.format('YYYY-MM-DD') >= dayjs(available_date).format('YYYY-MM-DD')) {
        const api_taskStatus = Object.entries({ ...taskStatusCol })
            .filter(([id, obj]) => obj.items.length > 0)
            .map(([id, obj]) => {
                return obj.items;
            })
            .flat();

        apiRequest('schedule', 'timeline', userId, api_taskStatus)
            .then((response) => {
                if (response.statusCode === 200) {
                    setSnackbarOpen(true);
                    setSnackSeverity('success');
                    setSnackMsg(response.body.message);
                } else {
                    setSnackbarOpen(true);
                    setSnackSeverity('error');
                    setSnackMsg(response.body.message);
                }
            })
            .catch((error) => {
                setSnackbarOpen(true);
                setSnackSeverity('error');
                setSnackMsg(error.response.message);
            });
        setSnackbarOpen(true);
        setSaveBtnDisable(true);
        // } else {
        //     window.alert('Please note that the timeline of 4 days ago cannot be modified.');
        // }
    };

    /** Handle to open container dialog. */
    const handleOpenDetail = (item) => {
        setNewMovementDialogOpen(true);
        const idValue = item.dispatch_id;
        /** Deep clone dispatch information to filter the data while not affecting the original state. */
        const filtered_movement = _.cloneDeep(dispatch)
            .filter((item) => item.id === idValue)
            .map((item) => item.movement)
            .flat();
        const filtered_container = _.cloneDeep(dispatch).filter((item) => item.id === idValue)[0].cntr;
        setTempMoveRow(filtered_movement);
        setSelectedCntr(filtered_container);
        setSelectedId(idValue);
    };

    const buttonRef = useRef(null);
    useEffect(() => {
        const handleKeyPress = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault();
                buttonRef.current.click();
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <>
            <DragDropContext
                onDragEnd={(result) =>
                    onDragEnd(result, taskStatusCol, setTaskStatusCol, setSaveBtnDisable, dispatch, setDispatch)
                }
            >
                <div name="outer" style={{ width: '100%' }}>
                    <Stack direction="row" justifyContent="end">
                        <Button
                            type="submit"
                            variant="contained"
                            sx={customized_style.itemButtons_save_opposite}
                            startIcon={<SaveIcon />}
                            disabled={saveBtnDisable}
                            onClick={handleSaveBtn}
                            ref={buttonRef}
                        >
                            SAVE
                        </Button>
                        <CustomSnackbar
                            snackbarOpen={snackbarOpen}
                            setSnackbarOpen={setSnackbarOpen}
                            snackMsg={snackMsg}
                            snackSeverity={snackSeverity}
                        />
                    </Stack>
                    {Object.entries(taskStatusCol).map(([columnId, column], index) => {
                        return (
                            <div key={columnId}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: columnId === 'movement' ? 'column' : 'row',
                                        alignItems: 'center',
                                        float: columnId === 'movement' ? 'left' : 'right',
                                    }}
                                    key={columnId}
                                >
                                    {columnId === 'movement' ? (
                                        <span style={{ fontSize: '15px', fontWeight: 'bold', color: '#003a54' }}>
                                            {column.name}
                                        </span>
                                    ) : (
                                        <span style={{ fontWeight: '500', color: '#003a54', fontSize: '13px' }}>
                                            {column.name}
                                        </span>
                                    )}
                                    <div style={{ margin: 8 }}>
                                        <Droppable
                                            droppableId={columnId}
                                            key={columnId}
                                            direction={columnId === 'movement' ? 'vertical' : 'horizontal'}
                                        >
                                            {(provided, snapshot) => {
                                                return (
                                                    <div
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                        key={columnId}
                                                        style={
                                                            columnId === 'movement'
                                                                ? {
                                                                      ...fieldStyle.droppable.movement,
                                                                      background: snapshot.isDraggingOver
                                                                          ? '#e2eafc'
                                                                          : '#edf2f4',
                                                                  }
                                                                : {
                                                                      ...fieldStyle.droppable.driver,
                                                                      background: snapshot.isDraggingOver
                                                                          ? '#e2eafc'
                                                                          : '#edf2f4',
                                                                      width:
                                                                          windowSize.width < 1800 ? '900px' : '1150px',
                                                                  }
                                                        }
                                                    >
                                                        {column.items?.map((item, index) => {
                                                            return (
                                                                <Draggable
                                                                    key={item.id.toString()}
                                                                    draggableId={item.id.toString()}
                                                                    index={index}
                                                                >
                                                                    {(provided, snapshot) => {
                                                                        return (
                                                                            <Box
                                                                                ref={provided.innerRef}
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                key={item.id.toString()}
                                                                                style={
                                                                                    item.movementstatus ==
                                                                                        'Completed' ||
                                                                                    item.movementstatus ==
                                                                                        'Street Turned'
                                                                                        ? {
                                                                                              ...fieldStyle.draggable
                                                                                                  .completed,
                                                                                              ...provided.draggableProps
                                                                                                  .style,
                                                                                          }
                                                                                        : item.movementstatus ==
                                                                                          'Failed'
                                                                                        ? {
                                                                                              ...fieldStyle.draggable
                                                                                                  .failed,
                                                                                              ...provided.draggableProps
                                                                                                  .style,
                                                                                          }
                                                                                        : item.type === 'Load'
                                                                                        ? {
                                                                                              ...fieldStyle.draggable
                                                                                                  .load,
                                                                                              ...provided.draggableProps
                                                                                                  .style,
                                                                                          }
                                                                                        : {
                                                                                              ...fieldStyle.draggable
                                                                                                  .empty,
                                                                                              ...provided.draggableProps
                                                                                                  .style,
                                                                                          }
                                                                                }
                                                                            >
                                                                                <IconButton
                                                                                    onClick={() =>
                                                                                        handleOpenDetail(item)
                                                                                    }
                                                                                    sx={{
                                                                                        width: '30px',
                                                                                        p: 0,
                                                                                        m: 0,
                                                                                        color: '#8a817c',
                                                                                    }}
                                                                                >
                                                                                    <ExpandCircleDownIcon />
                                                                                </IconButton>
                                                                                <Box style={fieldStyle.chip}>
                                                                                    {item.type.charAt(0)}
                                                                                </Box>
                                                                                <Box style={fieldStyle.chip}>
                                                                                    {item.method}
                                                                                </Box>
                                                                                <Box style={fieldStyle.chip}>
                                                                                    {item.size}
                                                                                </Box>
                                                                                <Box style={fieldStyle.chip}>
                                                                                    {
                                                                                        new Date(item.move_date)
                                                                                            .toLocaleDateString(
                                                                                                'en-US',
                                                                                                time_format
                                                                                            )
                                                                                            .split(', ')[1]
                                                                                    }
                                                                                </Box>
                                                                                <br />
                                                                                <Box
                                                                                    sx={{
                                                                                        fontSize: '0.7rem',
                                                                                    }}
                                                                                >
                                                                                    {item.container}
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            display: 'inline',
                                                                                            verticalAlign: 'middle',
                                                                                            fontSize: '0.8rem',
                                                                                            fontWeight: 'bold',
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            [...locationOptions].find(
                                                                                                (val) =>
                                                                                                    val.id ===
                                                                                                    item.pickup
                                                                                            )?.name
                                                                                        }

                                                                                        <DoubleArrowIcon
                                                                                            sx={{
                                                                                                verticalAlign: 'middle',
                                                                                                fontSize: '1rem',
                                                                                            }}
                                                                                        />
                                                                                        {
                                                                                            [...locationOptions].find(
                                                                                                (val) =>
                                                                                                    val.id ===
                                                                                                    item.delivery_to
                                                                                            )?.name
                                                                                        }
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Typography
                                                                                    sx={{
                                                                                        fontSize: '0.7rem',
                                                                                        fontWeight: 'medium',
                                                                                        color: '#6c757d',
                                                                                        p: 0,
                                                                                        m: 0,
                                                                                    }}
                                                                                >
                                                                                    {item.pickup_city != null
                                                                                        ? item.pickup_city + ', '
                                                                                        : ''}
                                                                                    {item.delivery_to_city != null
                                                                                        ? item.delivery_to_city + ', '
                                                                                        : ''}
                                                                                    {item.chassis_name != null
                                                                                        ? item.chassis_name
                                                                                        : ''}
                                                                                </Typography>
                                                                            </Box>
                                                                        );
                                                                    }}
                                                                </Draggable>
                                                            );
                                                        })}
                                                        {provided.placeholder}
                                                    </div>
                                                );
                                            }}
                                        </Droppable>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </DragDropContext>

            <TimelineCalendar
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                saveBtnDisable={saveBtnDisable}
            />
            <ChassisStatus
                userId={userId}
                dispatch={dispatch}
                chassis={chassis}
                chassisLocation={chassisLocation}
                setChassisLocation={setChassisLocation}
            />
            <MemoFab userId={userId} />
            <ScheduleFab type={load} bottomPosition={255} openUrl="/loadorder" />
            <ScheduleFab type={empty} bottomPosition={195} openUrl="/emptyorder" />
        </>
    );
}
