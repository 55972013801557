import * as React from 'react';
import { useState, useEffect } from 'react';

/** Material UI Packages. */
import { Box, Grid, Paper } from '@mui/material';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Library. */
import axios from 'axios';

/** Internal Components. */
import { DashboardDemCard, DashboardPdeimCard } from './DashboardCard';
import { DashboardDemTable, DashboardPdiemTable } from './DashboardTable';
import DashboardCount from './DashboardCount';
import DashboardAnnouncement from './DashboardAnnouncement';
import CustomSnackbar from '../../CustomSnackbar';
import { CustomSkeletonVer2, CustomSkeleton } from '../../CustomSkeleton';
import { dashboard_style, customized_style } from '../../../theme/Styles';

export default function Dashboard(props) {
    /** Get props from App.js.  */
    const { userId } = props;
    /** Initailize urgent container lists for Demurrage & PerDiem. */
    const [demCount, setDemCount] = useState(0);
    const [pdiemCount, setPdiemCount] = useState(0);
    const [loaded, setLoaded] = useState(false);

    /* Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    /** State to preserve API data. */
    const [demData, setDemData] = useState([]);
    const [perdiemData, setPerdiemData] = useState([]);
    const [movementCount, setMovementCount] = useState(0);
    const [containerCount, setContainerCount] = useState(0);
    const [memoData, setMemoData] = useState([]);

    /** Snack Bar message & Color */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackMsg, setSnackMsg] = useState('');
    const [snackSeverity, setSnackSeverity] = useState('success');

    /** Set document title. */
    useEffect(() => {
        document.title = 'Dashboard - Insight Logistics | Dispatch ';
    }, []);

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'dashboard',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setDemData(resp.body.demurrage);
                setPerdiemData(resp.body.perdiem);
                setMovementCount(resp.body.movement_length);
                setContainerCount(resp.body.schedule_length);
                setMemoData(resp.body.memo);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    /** Count the number of container for approaching date. */
    useEffect(() => {
        setDemCount(demData.length);
        setPdiemCount(perdiemData.length);
    }, [demData, perdiemData]);

    return (
        <Box maxWidth="xxl" sx={{ m: 2, minHeight: 720, height: '91vh' }}>
            {loaded ? (
                <Grid container spacing={2}>
                    <Grid item lg={3.3}>
                        <DashboardDemCard
                            demCount={demCount}
                            setDemData={setDemData}
                            setSnackMsg={setSnackMsg}
                            setSnackSeverity={setSnackSeverity}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <Paper sx={dashboard_style.itemPaper}>
                            <DashboardDemTable demCount={demCount} demData={demData} />
                        </Paper>
                    </Grid>
                    <Grid item lg={3.3}>
                        <DashboardPdeimCard
                            pdiemCount={pdiemCount}
                            setPerdiemData={setPerdiemData}
                            setSnackMsg={setSnackMsg}
                            setSnackSeverity={setSnackSeverity}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <Paper sx={dashboard_style.itemPaper}>
                            <DashboardPdiemTable perdiemData={perdiemData} />
                        </Paper>
                    </Grid>
                    <Grid item lg={5.4}>
                        <DashboardCount
                            movementCount={movementCount}
                            containerCount={containerCount}
                            setMovementCount={setMovementCount}
                            setContainerCount={setContainerCount}
                            setSnackMsg={setSnackMsg}
                            setSnackSeverity={setSnackSeverity}
                            setSnackbarOpen={setSnackbarOpen}
                        />
                        <Paper sx={dashboard_style.itemPaper}>
                            <DashboardAnnouncement
                                memoData={memoData}
                                setMemoData={setMemoData}
                                userId={userId}
                                snackbarOpen={snackbarOpen}
                                setSnackbarOpen={setSnackbarOpen}
                                snackMsg={snackMsg}
                                setSnackMsg={setSnackMsg}
                                snackSeverity={snackSeverity}
                                setSnackSeverity={setSnackSeverity}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2}>
                    <Grid item lg={3.3}>
                        <Paper sx={{ ...customized_style.itemPaper_main, p: 2, m: 0 }}>
                            <CustomSkeletonVer2 />
                        </Paper>
                    </Grid>
                    <Grid item lg={3.3}>
                        <Paper sx={{ ...customized_style.itemPaper_main, p: 2, m: 0 }}>
                            <CustomSkeletonVer2 />
                        </Paper>
                    </Grid>
                    <Grid item lg={5.4}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Paper sx={{ ...customized_style.itemPaper_main, p: 2, minHeight: 150, m: 0 }}>
                                    <CustomSkeleton />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper sx={{ ...customized_style.itemPaper_main, p: 2, minHeight: 150, m: 0 }}>
                                    <CustomSkeleton />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper sx={{ ...customized_style.itemPaper_main, p: 2, minHeight: 150, m: 0 }}>
                                    <CustomSkeleton />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper sx={{ ...customized_style.itemPaper_main, p: 2, minHeight: 500, mx: 0 }}>
                                    <CustomSkeleton />
                                    <CustomSkeleton />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <CustomSnackbar
                snackbarOpen={snackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                snackMsg={snackMsg}
                snackSeverity={snackSeverity}
            />
        </Box>
    );
}
