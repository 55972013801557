import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

/** Material UI Packages. */
import { Tabs, Tab, Typography, Box, Paper } from '@mui/material';
import PropTypes from 'prop-types';

/** Auth0 authenticator Packages. */
import { useAuth0 } from '@auth0/auth0-react';

/** HTTP Client Library. */
import axios from 'axios';

/** Internal Components. */
import RateContent from './RateContent';
import { CustomSkeletonVer2 } from '../../CustomSkeleton';
import { customized_style } from '../../../theme/Styles';

/** Create TabPanel component. */
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 2, minHeight: 650, height: '84vh' }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

/** To run typechecking on the props for a component. */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Rate(props) {
    /** Get props from App.js.  */
    const { userId } = props;

    /* Redirect user if the token is invalid */
    const { getAccessTokenSilently } = useAuth0();

    /** State to display skeleton component until API response is received. */
    const [loaded, setLoaded] = useState(false);

    /** States to preserve API data */
    const [driver, setDriver] = useState([]);
    const [description, setDescription] = useState([]);
    const [city, setCity] = useState([]);
    const [addCharge, setAddCharge] = useState([]);
    const [rates, setRates] = useState([]);

    /** Tab index value. */
    const [tabValue, setTabValue] = useState(0);
    /** Assigned Driver list  */
    const [saveList, setSaveList] = useState([]);
    /** Available drivers. */
    const [left, setLeft] = useState([]);
    /** Moved drivers to right transfer list (temporary data). */
    const [right, setRight] = useState([]);
    /** Check a state for save button activation. */
    const [saveBtnDisable, setSaveBtnDisable] = useState(true);
    /** All drivers list regardless of assignment. */
    const [allDriverList, setAllDriverList] = useState();
    /** Driver rate Info Datagrid row. */
    const [tabContent, setTabContent] = useState([]);
    /** Id value of Movement description. */
    const [idList, setIdList] = useState([]);
    /** Profile date from original data. */
    const [profileDate, setProfileDate] = useState([]);

    const rowUpdates = useRef(null);

    /** Set document title. */
    useEffect(() => {
        document.title = 'Rate - Insight Logistics | Dispatch ';
    }, []);

    /** Handle to change tabValue. */
    const tabHandleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    /** Initiallize all driver list.  */
    useEffect(() => {
        const new_left = [...driver].map((item) => `${item.first_name} ${item.last_name}`).sort();
        setAllDriverList(new_left);
    }, [driver]);

    /** Async API call to obtain dispatch - movement data. */
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience:
                            'https://insightshippinginc.us.auth0.com/api/v2/' /** Include the audience of authorization parameters to authenticate API call. */,
                    },
                });
                const response = await axios.get('https://icxhzxkwxa.execute-api.us-west-1.amazonaws.com/api', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: {
                        type: 'rate',
                        userId: userId,
                    },
                });
                const resp = response.data['body-json'];
                setDriver(resp.body.driver);
                setDescription(resp.body.description);
                setIdList([...resp.body.description].map((item) => item.id));
                setCity(resp.body.city);
                setAddCharge(resp.body.addCharge);
                setRates(resp.body.rate);
                setLoaded(true);
            } catch (e) {
                throw e;
            }
        })();
    }, [getAccessTokenSilently]);

    useEffect(() => {
        const new_tabContent = [...rates].map((profile) => {
            /**
             * Iterate through the rates profile.
             *  For each rate profile, and for each city and for each description...
             */
            const movement_rate = Object.values(
                [...profile.rate_applied].reduce((result, item) => {
                    const { city_name, description_id, description_name, rate } = item;
                    if (!result[description_id]) {
                        result[description_id] = { description_id, city_name };
                    }
                    result[description_id]['id'] = description_id;
                    result[description_id]['movement'] = description_name;
                    result[description_id][city_name] = rate;
                    return result;
                }, {})
            );
            // Construct additional charges if they exist, otherwise initialize with default values.
            const additional_rate =
                [...profile.addCharge_applied].length > 0
                    ? Object.values(
                          [...profile.addCharge_applied].reduce((result, item) => {
                              const { profile_id, additional_charge_name, rate } = item;
                              if (!result[profile_id]) {
                                  result[profile_id] = { profile_id, additional_charge_name, rate };
                              }
                              result[profile_id]['id'] = profile_id;
                              result[profile_id][additional_charge_name] = rate;
                              return result;
                          }, {})
                      )
                    : Object.values(
                          [...addCharge].reduce((result, item) => {
                              const { name } = item;
                              if (!result[profile.id]) {
                                  result[profile.id] = {};
                              }
                              result[profile.id]['id'] = profile.id;
                              result[profile.id][name] = 0;
                              return result;
                          }, {})
                      );

            // Create a new object based on the processed data and add it to 'new_tabContent'.
            return {
                id: profile.id,
                profile_id: profile.id,
                name: profile.name,
                content: [...movement_rate],
                addContent: [...additional_rate],
                date_applied: profile.date_applied,
            };
        });
        const driver_names = [...driver].reduce((map, obj) => {
            map[obj.id] = `${obj.first_name} ${obj.last_name}`;
            return map;
        }, {});
        // Generate driver list based on 'rates' and update state variables.
        const driver_list = [...rates]
            .map((item) => {
                const drivers = item.driver_applied.map((obj) => driver_names[obj.driver_id]);
                return { id: item.id, name: drivers };
            })
            .flat();
        const new_profileDate = [...rates].map((item) => ({
            id: item.id,
            date: item.date_applied,
        }));
        setSaveList(driver_list);
        setTabContent(new_tabContent);
        setProfileDate(new_profileDate);
    }, [rates]);

    /** Add empty object into tabContent in order to create new tab. */
    const createNewTab = () => {
        // Find  ID of the last tab in 'tabContent'.
        const lastTabId = tabContent.findLast((item, index) => index === tabContent.length - 1).id;
        // Create a new tab with default values.
        const newTab = {
            id: lastTabId + 1,
            name: 'Profile' + lastTabId.toString(),
            content: [...description].map((item) => {
                return {
                    id: item.id,
                    description_id: item.id,
                    movement: item.name,
                };
            }),
            addContent: Object.values(
                [...addCharge].reduce((result, item) => {
                    const { name } = item;
                    if (!result[lastTabId + 1]) {
                        result[lastTabId + 1] = {};
                    }
                    result[lastTabId + 1]['id'] = lastTabId + 1;
                    result[lastTabId + 1][name] = 0;
                    return result;
                }, {})
            ),
            isNew: true,
        };
        setTabContent((prev) => [...tabContent, newTab]);
        setSaveBtnDisable(false);
    };

    return (
        <>
            {loaded ? (
                <Paper sx={customized_style.itemPaper_main}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            value={tabValue}
                            onChange={tabHandleChange}
                            variant="scrollable"
                            textColor="inherit"
                            scrollButtons="auto"
                            indicatorColor="primary"
                        >
                            {tabContent.map((item, index) => (
                                <Tab key={item.id} label={item.name} />
                            ))}
                            <Tab onClick={createNewTab} label="CREATE" sx={{ color: 'primary.dark' }} />
                        </Tabs>
                    </Box>
                    {tabContent.map((item, index) => (
                        <TabPanel key={index} value={tabValue} index={index}>
                            <RateContent
                                profile_id={item.profile_id}
                                userId={userId}
                                tabContent={tabContent}
                                setTabContent={setTabContent}
                                tabValue={tabValue}
                                item={item}
                                allDriverList={allDriverList}
                                saveList={saveList}
                                setSaveList={setSaveList}
                                left={left}
                                setLeft={setLeft}
                                right={right}
                                setRight={setRight}
                                addCharge={addCharge}
                                city={city}
                                driver={driver}
                                saveBtnDisable={saveBtnDisable}
                                setSaveBtnDisable={setSaveBtnDisable}
                                rowUpdates={rowUpdates}
                                idList={idList}
                                profileDate={[...profileDate].filter((val) => val.id === item.id)}
                            />
                        </TabPanel>
                    ))}
                </Paper>
            ) : (
                <Paper sx={{ ...customized_style.itemPaper_main, p: 2 }}>
                    <CustomSkeletonVer2 />
                </Paper>
            )}
        </>
    );
}
