import { gridClasses } from '@mui/x-data-grid-premium';

/** CSS style.  */
export const dashboard_style = {
    itemPaper: {
        p: 1,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        overflow: 'auto',
        mt: 2,
    },
    itemPaper_small: {
        height: 150,
        width: '100%',
        borderRadius: 5,
    },
    itemDatagrid: {
        [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
        },
        [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
            outline: 'none',
        },
        border: 'none',
        '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
        },
    },
    itemTypographyCount: {
        color: 'white',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        mx: 2,
        mt: 1.5,
    },
};

export const customized_style = {
    itemPaper: {
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 5,
        overflow: 'auto',
    },
    itemPaper_main: {
        borderRadius: 5,
        mx: 2,
        my: 2,
        minHeight: 720,
        height: '89vh',
    },
    itemButtons_save: {
        color: 'black',
        bgcolor: 'white',
        fontWeight: 'bold',
        borderRadius: 2,
        mr: 1,
        ':hover': {
            backgroundColor: '#003a52',
            color: 'white',
        },
    },
    itemButtons_save_close: {
        color: 'black',
        bgcolor: 'white',
        fontWeight: 'bold',
        borderRadius: 2,
        ':hover': {
            backgroundColor: '#003a52',
            color: 'white',
        },
    },
    itemButtons_add: {
        width: '50%',
        bgcolor: '#00647e',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '0.9rem',
        mt: '6px',
        ':hover': {
            backgroundColor: '#003a52',
            color: 'white',
        },
    },
    itemButtons_save_opposite: {
        color: 'white',
        bgcolor: '#00647e',
        fontWeight: 'bold',
        mb: 2,
        ':hover': {
            backgroundColor: '#003a52',
            color: 'white',
        },
    },
    itemButtonRound: {
        display: 'flex',
        padding: '2px 4px',
        mb: '10px',
        border: '1px solid',
        borderRadius: '25px',
    },
    itemDatagrid: {
        width: '100%',
        boxShadow: 2,
        border: 1,
        borderColor: 'primary.light',
        '& .MuiDataGrid-cell:hover': {
            color: 'primary.main',
        },
    },
    itemTypography: {
        color: '#00657e',
        fontSize: '1.2rem',
        fontWeight: 'bold',
        mb: 3,
    },
    itemTextfield: {
        mb: 3,
        mr: 2,
        width: '250px',
    },
    itemMoveTypography: {
        color: '#00657e',
        fontSize: '0.9rem',
        fontWeight: 'bold',
        display: 'inline',
    },
    itemValueTypography: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        display: 'inline',
    },
    itemModal: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '70%',
        bgcolor: 'background.paper',
        border: '2px solid #00657e',
        boxShadow: 24,
        p: 3,
    },
};

export const menubar_style = {
    itemButtons: {
        color: '#003a52',
        ':hover': {
            borderRadius: 3,
            backgroundColor: '#80b2bf',
            color: ' white',
        },
    },
    subItemButtons: {
        color: '#003a52',
        ':hover': {
            borderRadius: 3,
            backgroundColor: '#80b2bf',
            color: ' white',
        },
        pl: 4,
    },
    itemTypography: {
        fontWeight: '500',
        fontSize: 15,
    },
    subItemTypography: {
        fontWeight: '500',
        fontSize: 13,
    },
};

export const fieldStyle = {
    droppable: {
        movement: {
            padding: 4,
            width: '245px',
            minHeight: 700,
            maxHeight: 900,
            display: 'inline-block',
            borderRadius: '4px',
            overflowY: 'auto',
        },
        driver: {
            minHeight: '70px',
            display: 'flex',
            padding: '2px',
            margin: '0',
            overflow: 'auto',
            borderRadius: '4px',
        },
    },
    draggable: {
        load: {
            width: '230px',
            minHeight: '60px',
            borderRadius: '0px',
            padding: 5,
            margin: 2,
            fontSize: '13px',
            fontWeight: 'bold',
            background: '#d9ed92',
            textAlign: 'center',
            color: '#0a0908',
        },
        empty: {
            width: '230px',
            minHeight: '60px',
            borderRadius: '0px',
            padding: 5,
            margin: 2,
            fontSize: '13px',
            fontWeight: 'bold',
            background: '#b8c0ff',
            textAlign: 'center',
            color: '#2b2d42',
        },
        completed: {
            width: '230px',
            minHeight: '60px',
            borderRadius: '0px',
            padding: 5,
            margin: 2,
            fontSize: '13px',
            fontWeight: 'bold',
            background: '#ced4da',
            textAlign: 'center',
            color: '#2b2d42',
        },
        failed: {
            width: '230px',
            minHeight: '60px',
            borderRadius: '0px',
            padding: 5,
            margin: 2,
            fontSize: '13px',
            fontWeight: 'bold',
            background: '#ff5a5f',
            textAlign: 'center',
            color: '#2b2d42',
        },
    },
    chip: {
        display: 'inline-block',
        padding: '0 6px',
        height: '20px',
        borderRadius: '25px',
        background: '#f1f1f1',
        marginLeft: '3px',
        fontSize: '12px',
    },
};
